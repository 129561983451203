/* # Forms ::
------------------------------------*/

.form-wrapper {
  .form-style-1 {
    font-family: 'Poppins-Regular', serif;
    @include fonts(14px, 14px, $color-black);
    border-radius: 10px;
    border-color: rgb(132, 132, 132);
    height: 47px;

    &::-webkit-input-placeholder {
      color: $color-silver-chalice-4;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: $color-silver-chalice-4;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: $color-silver-chalice-4;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: $color-silver-chalice-4;
      font-style: italic;
    }
    &.error {
      border-color: red;
      color: red;

      &::-webkit-input-placeholder {
        color: red;
      }
      &::-moz-placeholder {
        color: red;
      }
      &:-ms-input-placeholder {
        color: red;
      }
      &:-moz-placeholder {
        color: red;
      }
    }
  }

  .textarea-blc {
    margin: 0 0 25px;

    .textarea-style {
      font-family: 'Poppins-Regular', serif;
      @include fonts(14px, 14px, $color-black);
      border-radius: 10px;
      border: 1px solid rgb(132, 132, 132);
      @include dimensions(100%, 120px);
      padding: 0.75rem;
      margin: 0;

      &::-webkit-input-placeholder {
        color: $color-silver-chalice-4;
        font-style: italic;
      }
      &::-moz-placeholder {
        color: $color-silver-chalice-4;
        font-style: italic;
      }
      &:-ms-input-placeholder {
        color: $color-silver-chalice-4;
        font-style: italic;
      }
      &:-moz-placeholder {
        color: $color-silver-chalice-4;
        font-style: italic;
      }
      &.error {
        border-color: red;
        color: red;

        &::-webkit-input-placeholder {
          color: red;
        }
        &::-moz-placeholder {
          color: red;
        }
        &:-ms-input-placeholder {
          color: red;
        }
        &:-moz-placeholder {
          color: red;
        }
      }
    }
  }

}

.error-msg {
  margin: 7px 0 0;
  @include fonts(10px, 12px, red);
  font-family: 'Poppins-Regular', serif;
}

/* -- form-control -- */

.form-control {
  &.style-2 {
    border: 1px solid $color-silver-pale-oyster;
    margin: 0 0 15px;
    @include fonts(12px, 12px, $color-silver-pale-oyster);
    height: 35px;
  }
}

/* # $Mobile ::
------------------------------------*/

@media (max-width: 992px) {
  .form-wrapper {
    .textarea-blc {
      margin: 0 0 10px;
    }
  }
}