/* # Buttons ::
------------------------------------*/

.btn {
    width: 100%;

  &.btn-border {
    min-width: 84px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid $color-white;
    font-family: 'Poppins-Light', serif;
    @include fonts(14px, 14px, $color-white);
    display: block;
    transition: all 0.3s;
    height: 35px;
    width: initial;

    &:hover {
      background-color: #ef8e3882;
      border: 2px solid $color-orange-jaffa;
    }
  }
  &.btn-gray {
    border-radius: 10px;
    background-color: $color-gray-spring-wood;
    border: 1px solid $color-bali-hai;
    font-family: 'Poppins-Light', serif;
    @include fonts(14px, 14px, $color-bali-hai);
    display: block;
    transition: all 0.3s;
    height: 35px;
    width: initial;
  }
  &.btn-orange {
    border-radius: 10px;
    background-color: $color-orange-jaffa;
    border: 2px solid $color-orange-jaffa;
    font-family: 'Inter-Medium', serif;
    @include fonts(15px, 15px, $color-white);
    display: block;
    transition: all 0.3s;
    height: 45px; // 35px;
    width: initial;

    &.style-1 {
      min-width: 185px; // 84px;
      margin: 0 auto;
    }
    &.style-2 {
      min-width: 160px;
      height: 38px;
    }
    &:hover {
      background-color: #ef8e3882;
    }
  }
  &.btn-outline-orange {
    border-radius: 10px;
    background-color: $color-white;
    border: 2px solid $color-orange-jaffa;
    font-family: 'Poppins-Light', serif;
    @include fonts(14px, 14px, $color-orange-jaffa);
    display: block;
    transition: all 0.3s;
    height: 35px;
    width: initial;

    &.style-1 {
      min-width: 84px;
      margin: 0 auto;
    }
    &.style-2 {
      min-width: 160px;
      height: 38px;
    }
    &.style-3 {
      min-width: 160px;
      height: 40px;
      border-width: 1px;
    }
    &:hover {
      background-color: $color-orange-jaffa;
      color: $color-white;
    }
  }
  &.btn-more {
    background-image: url('../../assets/images/description-toggle-icn.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 29px auto;
    @include dimensions(29px, 29px);
    display: block;
    margin: 0 auto;
  }
  &.btn-blue-viking {
    border-radius: 10px;
    font-family: 'Poppins-Light', serif;
    @include fonts(14px, 14px, $color-white);
    display: block;
    transition: all 0.3s;
    height: 35px;
    width: initial;
    padding: 0.375rem 0.75rem 0.375rem 2.5rem;
    background-color: $color-blue-viking;
    background-image: url('./../../assets/img/icon/add.png');
    background-repeat: no-repeat;
    background-size: 13px auto;
    background-position: 12px center;
  }
}
.title-bloc {
  display: flex;
  .back {
    background-image: url("../../assets/img/svg/arrow-back.svg");
    background-position: left center;
    background-size: 24px auto;
    background-repeat: no-repeat;
    padding: 0 0 0 30px;
    cursor: pointer;
  }
}
/* # $Mobile ::
------------------------------------*/

@media (max-width: 1440px) {
  .btn {
    &.btn-orange {
      @include fontSize(12px, 12px);
    }
  }
}

@media (max-width: 992px) {
  .btn {
    &.btn-orange {
      &.style-1 {
        min-width: 145px;
        height: 40px;
      }
    }
  }
}

