/* # Variables ::
------------------------------------*/

/* -- Colors - */
$color-white: #fff;
$color-white-pearl: #efe9e2;
$color-black: #000;
$color-black-woodsmoke: #0e1114;
$color-black-mine-shaft: #212121;
$color-black-tundora: #464646;
$color-black-tundora-2: #484848;
$color-black-tundora-3: #444242;
$color-gray-cod: #1a1a1a;
$color-silver-chalice: #ababab;
$color-gray-1: #8f8c8c;
$color-gray-2: #808080;
$color-gray-3: #7f7f7f;
$color-gray-scorpion: #585656;
$color-athens-gray: #F5F7F9;
$color-athens-gray-2: #e8eaee;
$color-gray-mercury: #E2E1E1;
$color-gray-pampas: #ede9e5;
$color-gray-dusty: #989898;
$color-gray-dusty-2: #9E9999;
$color-gray-gallery: #F0F0F0;
$color-gray-gallery-2: #eeeeee;
$color-gray-gallery-3: #ebebeb;
$color-gray-concrete: #F4F2F2;
$color-gray-wild-sand: #F5F5F5;
$color-gray-nomad: #bbb1a3;
$color-gray-spring-wood: #f8f6f2;
$color-gray-del-sol: #5C502F;
$color-gray-ghost: #ced4da;
$color-gray-slate: #64748b;
$color-golden-dream: #EDC72F;
$color-golden-rod: #FDDF65;
$color-blue-eastern: #1F94A4;
$color-blue-mariner: #2462d2;
$color-blue-dianne: #264E62;
$color-blue-havelock: #4988DD;
$color-blue-polo: #91a9c9;
$color-blue-malibu: #86b7fe;
$color-jungle-mist: #A6CBD0;
$color-bali-hai: #8e96b8;
$color-bluewood: #2f455c;
$color-zircon: #F8F9FF;
$color-ebb-gray: #e4dcdc;
$color-alto-gray: #e0e0e0;
$color-alto-gray-2: #dbdbdb;
$color-alto-gray-3: #dddddd;
$color-mid-gray: #5a596a;
$color-wild-sand: #F5F5F5;
$color-silver: #bfbfbf;
$color-silver-2: #ccc;
$color-silver-chalice-3: #9e9e9e;
$color-silver-chalice-4: #aaaaaa;
$color-silver-chalice-5: #acabab;
$color-silver-pale-oyster: #968e7e;
$color-orange-jaffa: #ef8e38;
$color-orange-jaffa-2: #ed7136;
$color-orange-blaza: #ff6600;
$color-orange-tango: #f27228;
$color-orange-tango-2: #f37121;
$color-orange-tanhide: #f69459;
$color-orange-chardonnay: #FFC38E;
$color-orange-champagne: #FBEADB;
$color-half-baked: #7ec1cb;
$color-blue-viking: #4fc9da;
$color-blue-foam: #ddf8fc;
$color-gray-wild-sand-2: #f6f6f6;
$color-green-salem: #0aa524;

/* -- Imgs url - */
$img-arrow-next: url('../../assets/images/arrow-next-icn-1.png');
$arrow-pagination-next: url('../../assets/images/arrow-pagination-next.svg');
$arrow-pagination-prev: url('../../assets/images/arrow-pagination-prev.svg');



