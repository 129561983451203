@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('Montserrat-ExtraBold.eot');
    src: url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff'),
        url('Montserrat-ExtraBold.ttf') format('truetype'),
        url('Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Black';
    src: url('Montserrat-Black.eot');
    src: url('Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Black.woff2') format('woff2'),
        url('Montserrat-Black.woff') format('woff'),
        url('Montserrat-Black.ttf') format('truetype'),
        url('Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff'),
        url('Montserrat-Bold.ttf') format('truetype'),
        url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('Montserrat-Light.eot');
    src: url('Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Light.woff2') format('woff2'),
        url('Montserrat-Light.woff') format('woff'),
        url('Montserrat-Light.ttf') format('truetype'),
        url('Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('Montserrat-Medium.eot');
    src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff'),
        url('Montserrat-Medium.ttf') format('truetype'),
        url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('Montserrat-Regular.eot');
    src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype'),
        url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('Montserrat-SemiBold.eot');
    src: url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff'),
        url('Montserrat-SemiBold.ttf') format('truetype'),
        url('Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('Montserrat-Thin.eot');
    src: url('Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Thin.woff2') format('woff2'),
        url('Montserrat-Thin.woff') format('woff'),
        url('Montserrat-Thin.ttf') format('truetype'),
        url('Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

