/* # Global ::
------------------------------------*/

.loader {
  @include dimensions(100%, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  pointer-events: none;
  z-index: 999;

  .img-loading {
    background-image: url('./../../assets/img/stpo_logo.png');
    @include dimensions;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150px;
    animation: fade 1s infinite;
  }
}
.loading-active{
  overflow: hidden;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


body {
    padding: 0;
    margin: 0;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    cursor: pointer;
}

/* -- Grids -- */

.grid {
    display: grid;

    &.gtc-1 {
        grid-template-columns: repeat(2, 1fr);
    }
    &.gtc-2 {
        grid-template-columns: 365px 1fr;
    }
    &.gtc-3 {
        grid-template-columns: 270px 1fr 1fr 1fr;
    }
    &.gtc-4 {
      grid-template-columns: 1fr 350px;
    }
    &.gtc-5 {
      grid-template-columns: 350px 1fr;
    }
    &.gtc-6 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.gtc-7 {
      grid-template-columns: 270px 1fr 1fr;
    }
    &.gcg-1 {
        grid-column-gap: 10px;
    }
    &.gcg-2 {
        grid-column-gap: 20px;
    }
    &.gcg-3 {
      grid-column-gap: 24px;
    }
    &.gg-1 {
        grid-gap: 60px;
    }
    &.gg-2 {
        grid-gap: 50px;
    }
    &.gg-3 {
        grid-gap: 30px;
    }
}

/* -- Titles -- */

.section-title-blc {
    max-width: 578px;
    margin: 0 auto 50px;

    .section-title {
      font-family: 'Poppins-SemiBold', serif;
      @include fonts(28px, 42px, $color-blue-dianne);
      text-align: center;
      margin: 0;
      text-transform: uppercase;
    }
}


/* -- Page Layout Header -- */

.page-layout {
  margin: 95px 0 0;

  .page-layout-header-area {
    height: 410px;
    position: relative;

    .page-layout-img-blc {
      @include dimensions(100%, 410px);
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
      }
      img {
        @include dimensions;
        object-fit: cover;
        object-position: center;
      }
    }
    .page-layout-header_description {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include flex;
      padding: 0 160px;

      .page-layout-header_title {
        @include fonts(49px, 49px, $color-white);
        font-family: 'Poppins-Medium', serif;
        text-transform: uppercase;
        margin: 0 0 49px;
        text-align: center;
      }
      .page-layout-header_desc {
        @include fonts(22px, 30px, $color-white);
        font-family: 'Poppins-Medium', serif;
        margin: 0;
        text-align: center;
      }
    }
  }
}


/* -- Breadcrumb -- */

.breadcrumb-area {
  .breadcrumb {
    .breadcrumb-item {
      @include fonts(14px, 14px, $color-black-mine-shaft);
      font-family: 'Poppins-Light', serif;

      &:not(:first-child) {
        &:before {
          content: var(--bs-breadcrumb-divider, "|");
        }
      }
      a {
        @include fonts(14px, 14px, $color-black-mine-shaft);
        font-family: 'Poppins-Light', serif;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/* -- our-works-slider-area -- */

.carousel-area {
  .slide {
    margin: 0 15px;
  }
  .slick-list {
    padding: 15px 50px !important;
    max-width: 999px;
    margin: 0 auto;
  }
  .slick-arrow {
    @include dimensions(12px, 20px);
    background-repeat: no-repeat;
    background-size: 12px auto;
    background-position: center;
    z-index: 2;

    &:before {
      display: none;
    }
    &.slick-prev {
      background-image: url('../../assets/img/svg/arrow-slider-icn.svg');
      left: 0;
      transform: rotate(180deg);
    }
    &.slick-next {
      background-image: url('../../assets/img/svg/arrow-slider-icn.svg');
      right: 0;
    }
  }
}

/* -- carousel -- */
.carousel {
  .slick-list {
    padding: 15px 60px !important;
  }
  .our-works-item {
    max-width: 294px;
    min-height: 347px;
    box-shadow: 0 0 18px 0 rgba(91, 90, 90, 0.21);
    box-shadow: 10px 10px 9px 0px #7D7D7D2E;
    background-color: $color-white;
    border-radius: 10px;

    .our-works-item_img-blc {
      height: 178px;
      background-color: $color-alto-gray;
      @include flex;
      overflow: hidden;
      border-radius: 10px 10px 0 0;

      img {
        width: 100%;
      }
    }
    .our-works-item_description-area {
      padding: 20px;
      border-bottom: 5px solid $color-orange-jaffa;
      height: 188px;
      border-radius: 0 0 10px 10px;

      .our-works-item_desc-title {
        font-family: 'Poppins-Medium', serif;
        @include fonts(14px, 18px, $color-black);
        margin: 0 0 15px;
        text-align: center;
      }
      p {
        font-family: 'Poppins-Light', serif;
        @include fonts(12px, 18px, $color-black);
        margin: 0 0 15px;
        @include truncate(2);
        height: 37px;
        text-align: center;
      }
      .btns-group {
        @include flex;

        .btn {
          min-width: 116px;
          height: 30px;
          @include fontSize(12px, 12px);
        }
      }
    }
  }
}

/* -- request-quotation-area -- */

.request-quotation-area {
  .aside-contact-area {
    .informations-list-area {
      .information-list-item {
        margin: 0 0 50px;
        padding: 0 0 0 60px;
        background-repeat: no-repeat;
        background-position: left 0;
        background-size: auto;

        &:not(:last-child) {
          margin: 0 0 50px;
        }
        &.our-location-icn {
          background-image: url('../../assets/images/our-location-black-icn.png');
          background-size: 45px auto;
        }
        &.quick-touch-icn {
          background-image: url('../../assets/images/quick-touch-black-icn.png');
          background-size: 39px auto;
        }
        &.call-us-now-icn {
          background-image: url('../../assets/images/call-us-now-black-icn.png');
          background-size: 34px auto;
        }
        &.opening-hours-icn {
          background-image: url('../../assets/images/opening-hours-icn.png');
          background-size: 38px auto;
        }

        .info-list-title {
          @include fonts(20px, 20px, $color-black);
          font-family: 'Poppins-SemiBold', serif;
          margin: 0 0 20px;
        }
        .info-list-desc {
          @include fonts(16px, 16px, $color-black);
          font-family: 'Poppins-Regular', serif;
          margin: 0;

          &:not(:last-child) {
            margin: 0 0 15px;
          }
        }
      }
    }
  }
  .contact-form-area {
    .contact-form_header {
      margin: 0 0 25px;

      .contact-form_header-title {
        font-family: 'Poppins-Bold', serif;
        @include fonts(24px, 24px, $color-black);
        margin: 0 0 25px;
      }
      .contact-form_header-desc {
        font-family: 'Poppins-Medium', serif;
        @include fonts(16px, 25px, $color-black);
        margin: 0;
      }
    }
    .grid {
      margin: 0 0 25px;
    }
    .btns-group {
      .btn {
        @include dimensions(100%, 48px);
      }
    }
    .form-wrapper {
      .form-style-1 {
        font-family: "Poppins-Regular", serif;
        @include fonts(14px, 14px, $color-gray-dusty);
        border-radius: 10px;
        border-color: $color-gray-gallery-2;
        background-color: $color-gray-gallery-2;
        height: 47px;
      }
      .textarea-blc {
        .textarea-style {
          font-family: "Poppins-Regular", serif;
          @include fonts(14px, 14px, $color-gray-dusty);
          border-radius: 10px;
          border-color: $color-gray-gallery-2;
          background-color: $color-gray-gallery-2;
        }
      }
    }
    .btns-group {
      .btn {
        width: initial;
        min-width: 270px;
        margin: 0 0 0 auto;
      }
    }
  }
}

/* -- tabset-area -- */

.tabset-area {
  .nav {
    &.nav-tabs {
      box-shadow: 0 0 18px 0 rgba(91, 90, 90, 0.21);
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .nav-item {
        position: relative;

        &:not(:last-child) {
          &:after {
            content: '';
            display: block;
            @include dimensions(1px, 48px);
            background-color: $color-alto-gray-2;
            position: absolute;
            top: 5px;
            right: 0;
          }
        }
      }
      .nav-link {
        font-family: 'Poppins-SemiBold', serif;
        @include fonts(16px, 16px, $color-gray-dusty);
        @include flex;
        @include dimensions(100%, 50px);
        margin-bottom: 0;

        &.active {
          color: $color-orange-jaffa;
          border: 0;
          position: relative;

          &:after {
            content: '';
            display: block;
            @include dimensions(100%, 20px);
            position: absolute;
            bottom: -19px;
            left: 0;
            right: 0;
            background-color: $color-white;
          }
        }
      }
    }
  }
  .tab-content {
    padding: 20px 0 0;
  }

  &.style-2 {
    .nav {
      &.nav-tabs {
        box-shadow: none;
        display: flex;

        .nav-item {
          &:not(:last-child):after {
            display: none;
          }
        }
        .nav-link {
          &.active {
            border-bottom: 2px solid $color-orange-jaffa;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}


/* -- similar-products-area -- */

.similar-products-area {
  margin: 0 0 100px;

  .similar-products_title-blc {
    margin: 0 0 40px;

    .similar-products_title {
      @include fonts(18px, 18px, $color-orange-blaza);
      font-family: 'Poppins-Medium', serif;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
    }
  }
}

/* -- request-quotation-area -- */

.request-quotation-area {
  padding: 80px 0 0;
  border-top: 2px solid $color-gray-2;
}

/* -- radio-group -- */

.radio-group {
  display: flex;
  height: 50px;
  background-color: $color-white;
  border: 2px solid $color-orange-tango-2;
  border-radius: 5px;

  input[type="radio"] {
    visibility: hidden;
    @include dimensions(0, 0);
  }
  label {
    @include flex;
    flex: auto;
    vertical-align: middle;
    cursor: pointer;
    padding: 5px 10px;
    user-select: none;
    @include fonts(16px, 16px, $color-orange-tango-2);
    font-family: 'Poppins-Light', serif;

    &:last-of-type {
      margin-right: 0;
    }
  }
  input[type="radio"]:checked + label {
    background-color: $color-orange-tango-2;
    color: $color-white;
  }
}

/* -- Card -- */

.card {
  box-shadow: 0 3px 16px #0000001c;
  background-color: $color-gray-wild-sand-2;
  border-radius: 15px;
  border: 0;
  margin: 0 0 20px;

  .card_header {
    border-bottom: 1px solid $color-silver;
    height: 40px;
    padding: 10px 18px;
    display: flex;
    align-items: center;

    .card_header-title {
      margin: 0;
      @include fontSize(13px, 13px);
    }
  }
  .card_body {
    padding: 10px;
  }

  &.style-2 {
    .card_header {
      .card_header-title {
        background-repeat: no-repeat;
        background-position: left center;
        padding: 5px 0 5px 25px;
        color: $color-blue-eastern;

        &.client-icn {
          // background-image: url('./../../assets/img/icon/face-smile-client-icn.png');
          background-size: 20px auto;
        }
        &.fournisseur-icn {
          // background-image: url('./../../assets/img/icon/vendor-icn.png');
          background-size: 22px auto;
        }
        &.product-icn {
          // background-image: url('./../../assets/img/icon/product-icn.png');
          background-size: 18px auto;
        }
        &.state-icn {
          // background-image: url('./../../assets/img/icon/undo-arrow.png');
          background-size: 18px auto;
        }
        &.paiement-icn {
          // background-image: url('./../../assets/img/icon/credit-card.png');
          background-size: 18px auto;
        }
      }
    }
  }
  &.style-3 {
    padding: 25px 15px;
  }
  &.style-5 {
    padding: 20px;
    box-shadow: none;
    border: 1px dashed $color-white-pearl;
    background-color: $color-white;
    border-radius: 10px;

    .card_header {
      border-bottom: 0;
      height: initial;
      padding: 0;
      display: block;
      margin: 0 0 25px;

      .card_header-title {
        margin: 0;
        @include fontSize(14px, 14px);
        font-family: 'Poppins-SemiBold', serif;
      }
    }
    .card_body {
      padding: 10px;
    }
  }
}

/* -- ng-select-area -- */

.ng-select-area {
  margin: 0 0 15px;
  @include flex(flex, center, end);

  .ng-select {
    .ng-select-container {
      min-width: 140px;

      .ng-value-container {
        .ng-placeholder {
          @include fontSize(13px, 16px);
        }
        .ng-value {
          display: flex;
          align-items: center;

          img {
            margin: 0 7px 0 0;
            width: 18px;
            object-fit: fill;
          }
          b {
            @include fontSize(11px, 11px);
          }
        }
      }
    }
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          display: flex;
          align-items: center;

          img {
            margin: 0 7px 0 0;
            width: 18px;
            object-fit: fill;
          }
          b {
            @include fontSize(11px, 11px);
          }
        }
      }
    }
  }
}

/* -- image-bloc -- */

.image-bloc {
  position: relative;
  @include dimensions(160px, 160px);
  box-shadow: 0 0 1em $color-gray-pampas;
  margin: 14px auto 0;
  @include flex;

  .edit-img-btn {
    position: absolute;
    right: 0;
    top:-8px;
    border: none;
    background-image: url("./../../assets/img/edit-file.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px auto;
    padding: 15px;
    border-radius: 20px;
    background-color: $color-white;
    box-shadow: 0.1em 0 0.4em $color-gray-pampas;
  }
  .file-upload-button {
    input[type="file"] {
      font-size: 100px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      @include dimensions(25px, 25px);
    }
  }
}

/* -- file-input -- */

.file-input {
  cursor: pointer;
  background-color: #ef8e3866;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='%23ef8e38' d='M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 35px auto;
  background-position: 20px center;
  padding: 25px 25px 25px 80px;
  border: 1px dashed $color-orange-jaffa;
  border-radius: 15px;
  margin: 0 0 15px;

  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .file-input__label {
    span {
      display: block;

      &:nth-child(1) {
        @include fonts(13px, 13px, $color-black);
        margin: 0 0 10px;
      }
      &:nth-child(2) {
        @include fonts(12px, 12px, $color-gray-nomad);
      }
    }
  }
}


/* -- Error Msg */

.error {
  @include fonts(10px, 10px, red);
}

/* -- Radio Lang */

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .input-container {
    position: relative;
    margin: 0.5rem;
    width: 80px;

    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      @include dimensions;
      margin: 0;
      cursor: pointer;
      &:disabled{
        cursor: default;
      }
    }
    .radio-tile {
      @include flex;
      flex-direction: column;
      @include dimensions;
      border: 2px solid $color-gray-slate;
      border-radius: 5px;
      padding: 10px;
      transition: transform 300ms ease;

      .icon {
        background-repeat: no-repeat;
        background-size: 35px auto;
        background-position: center;
        @include dimensions(35px, 35px);
        border-radius: 50%;
        margin: 0 auto 5px;

        &.french-icon {
          background-image: url('./../../assets/img/france-flag.png');
        }
        &.anglais-icon {
          background-image: url('./../../assets/img/united-kingdom-flag.png');
        }
        &.ar-icon {
          background-image: url('./../../assets/img/tunisia-flag.png');
        }

      }
    }
    .radio-tile-label {
      text-align: center;
      @include fonts(11px, 11px, $color-gray-slate);
      text-transform: capitalize;
      letter-spacing: 1px;
    }

    .radio-button:checked + .radio-tile {
      background-color: $color-orange-jaffa-2;
      border: 2px solid $color-orange-jaffa-2;
      color: $color-white;
      transform: scale(1.1, 1.1);

      .radio-tile-label {
        color: $color-white;
        background-color: $color-orange-jaffa-2;
      }
    }
  }
}

/* -- To Top -- */
.to-top {
  @include dimensions(36px, 36px);
  border-radius: 3px;
  border: 0;
  margin: 0 8px 12px 0;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: $color-orange-tango-2;
  background-image: url('./../../assets/img/arrow-up.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px auto;
}

/* -- radio-tile-group -- */

.editor-area {
  .ql-formats {
    button {
      border: 0;
      @include dimensions(35px, 30px);
    }
  }
}

.modal-new-private-product-area .carousel .slick-list{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-product-category-area .carousel .slick-list{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lang-select.ng-select.ng-select-single {
  margin-right: 10px;
  &:not(.select-lang-header){
    .ng-select-container{
      background-color: $color-white;
      border: 1px solid $color-gray-mercury;
      border-radius: 6px;
      height: 34px;
    }
  }
  &.select-lang-header {
    text-transform: capitalize;
      font-weight: 400;
      color: $color-white;
      font-family: "Poppins-Regular", serif;
      font-size: 14px;
      .ng-placeholder {
        text-transform: capitalize;
        font-weight: 400;
        color: $color-white;
        font-family: "Poppins-Regular", serif;
        font-size: 14px;
        margin-left: -10px;

      }
      .ng-placeholder + .ng-value {
        text-transform: capitalize;
        font-weight: 400;
        color: $color-white;
        font-family: "Poppins-Regular", serif;
        font-size: 14px;
      }
    .ng-select-container{
      background-color: transparent;
      border: none;
      height: auto;
      min-height: auto;
      &:hover{
        box-shadow: none;
      }
    }
    span{
      text-transform: capitalize;
      font-weight: 400;
      color: $color-white;
      font-family: "Poppins-Regular", serif;
      font-size: 12px;
    }
    .ng-dropdown-panel{
      width: max-content;
      border: none;
      top: 150%;
      span{
        color: $color-mid-gray;
      }
      .ng-option-selected{
        background-color: $color-orange-jaffa;
        span{
          color: $color-white;
        }
      }
      .ng-option{
        &:hover{
          background-color: $color-white;
          span{
            color: $color-orange-jaffa;
          }
        }
        &:focus{
          background-color: $color-white;

          span{
            color: $color-mid-gray;
          }
        }
      }
    }
    &.ng-select-opened>.ng-select-container .ng-arrow{
      border-color: transparent transparent $color-white;
    }
    &:not(.ng-select-opened)>.ng-select-container .ng-arrow{
      border-color: $color-white transparent transparent;
    }
  }
  .ng-select-container{
    width: inherit;
    font-weight: 400;
    background-clip: padding-box;
    font-family: "Poppins-Medium", serif;
    @include fonts(12px, 15px, $color-black);

    .ng-value-container .ng-input>input{
      visibility: hidden;
    }
  }
}
.p-paginator {
  .p-paginator-pages{
    .p-paginator-page{
      @include dimensions(2rem, 2rem);
      min-width: 2rem;
    }
  }
  .p-paginator-current{
    height: 2rem;
  }
  .p-paginator-first{
    height: 2rem;
  }
  .p-paginator-prev{
    height: 2rem;
  }
  .p-paginator-next{
    height: 2rem;
  }
  .p-paginator-last{
    height: 2rem;
  }
  .p-dropdown{
    height: fit-content;
    .p-dropdown-label {
      padding: 2px 5px;
    }
  }
}
.p-datatable .p-paginator-bottom{
  padding: 5px 0px;
}


.p-dialog-content{
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: $color-wild-sand;
  }
  &::-webkit-scrollbar {
    @include dimensions(6px, 6px);
    background-color: $color-wild-sand;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $color-silver-chalice-5;
  }
}
.cke_top{
  padding-top: 20px !important;
  background: $color-white !important;
}
.cke_chrome{
  border-radius: 0.25rem !important;
  padding: 1px !important;
}
.cke_bottom{
  background: $color-white !important;
}
.form-label-placeholder {
  margin-bottom: 10px;
  position: relative;
  z-index: 0;  
  input, textarea, .form-html{
    font-family: "Poppins-Medium", serif;
    z-index: 1;
    background-color: transparent;
    font-size: 12px;
    
    &::placeholder {    
      opacity: 0;
    }
    &:focus, &.focus{
      box-shadow: none;
      + .form-label, +.label-style{
        top: -10px;
        z-index: 9;
      }
    }
    +.form-label, +.label-style{
      text-transform: capitalize;
      right: auto;
      padding: 0 5px;
      background-color: $color-white;
      transition-property: all; 
      transition-timing-function: ease;
      transition-duration: .2s;
      position: absolute;
      top: 10px;
      left: 8px;
      // right: 12px;
      transition: all .2s;
      color: $color-blue-polo !important;
      letter-spacing: .1px;
      font-size: 10px !important;
      line-height: 10px !important;
      padding: 5px;
      background-color: $color-white;
      z-index: -1;
      text-transform: uppercase;
      margin-left: 0 !important;
    }    
  }
  ckeditor{
    +.form-label{
      text-transform: capitalize;
      right: auto;
      padding: 0 5px;
      background-color: $color-white;
      position: absolute;
      top: -11px;
      left: 8px;
      transition: all .2s;
      letter-spacing: .1px;
      @include fonts(10px, 10px, $color-blue-polo);
      padding: 5px;
      background-color: $color-white;
      z-index: 9;
      text-transform: uppercase;
    }
  }
  input{
    padding: 10px 10px;
  }
  textarea{
    padding: 13px 10px;
    height: 106px;
  }
}
.form-label-placeholder-dropdown{
  position: relative;
  p-dropdown{
    +.form-label{
      text-transform: capitalize;
      right: auto;
      padding: 0 5px;
      background-color: $color-white;
      position: absolute;
      top: 10px;
      left: 8px;
      transition: all .2s;
      letter-spacing: .1px;
      @include fonts(10px, 10px, $color-blue-polo);
      padding: 5px;
      background-color: $color-white;
      z-index: 0;
      text-transform: uppercase;
    }
    .p-placeholder{
      color: rgba(0, 0, 0, 0) !important;
      z-index: 9;
    }
    &.p-inputwrapper-focus, &.focus{      
      +.form-label{
        top: -11px;
        z-index: 9;
      }
      .p-dropdown{
        box-shadow: none !important;
        border-color: $color-blue-malibu;
      }
    }
    .p-dropdown:hover{
      box-shadow: none !important;
      border-color: $color-blue-malibu;
    }
  }
}
.form-label-file{
  .form-label{
      text-transform: capitalize;
      right: auto;
      padding: 0 5px;
      background-color: $color-white;    
      transition: all .2s;
      letter-spacing: .1px;
      @include fonts(10px, 10px, $color-blue-polo);
      padding: 5px;
      background-color: $color-white;
      z-index: 0;
      text-transform: uppercase;
      &.orange{
        color: $color-orange-jaffa;
      }
  }
}


/* # $Mobile ::
------------------------------------*/

@media (max-width: 1440px) {
  .page-layout {
    margin: 76px 0 0;
  }
  .tabset-area {
    .nav.nav-tabs {
      .nav-link {
        @include fontSize(13px, 13px);
        height: 40px;
      }
    }
    
  }
  /*
  .carousel-area {
    .slick-arrow {
      @include dimensions(24px, 36px);
      background-size: 24px auto;
    }
  }
  */
  .breadcrumb-area {
    .breadcrumb {
      .breadcrumb-item {
        @include fontSize(12px, 15px);

        a {
          @include fontSize(12px, 12px);
        }
      }
    }
  }
  .page-layout {
    .page-layout-header-area {
      .page-layout-header_description {
        .page-layout-header_title {
          @include fontSize(40px, 40px);
          margin: 0 0 40px;
        }
        .page-layout-header_desc {
          @include fontSize(18px, 26px);
        }
      }
    }
  }
  .grid {
    &.gg-2 {
      grid-gap: 30px;
    }
  }
  .request-quotation-area {
    padding: 50px 0 0;

    .aside-contact-area {
      .informations-list-area {
        .information-list-item {
          margin: 0 0 40px;
          padding: 0 0 0 50px;

          &:not(:last-child) {
            margin: 0 0 40px;
          }
          &.our-location-icn {
            background-size: 35px auto;
          }
          &.quick-touch-icn {
            background-size: 30px auto;
          }
          &.call-us-now-icn {
            background-size: 30px auto;
          }
          .info-list-title {
            @include fontSize(16px, 16px);
            margin: 0 0 10px;
          }
          .info-list-desc {
            font-size: 13px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .request-quotation-area {
    .contact-form-area {
      .grid {
        margin: 0 0 10px;
        grid-template-columns: repeat(1, 1fr);

        &.gg-3 {
          grid-gap: 10px;
        }
      }
      .contact-form_header {
        margin: 0 0 20px;

        .contact-form_header-title {
          @include fontSize(20px, 20px);
          margin: 0 0 20px;
        }
        .contact-form_header-desc {
          @include fontSize(13px, 20px);
        }
      }
      .btns-group {
        .btn {
          min-width: 100%;
          margin: 0;
        }
      }
      .form-wrapper {
        .form-style-1 {
          @include fontSize(12px, 12px);
          height: 35px;
        }
      }
    }
  }
  header {
    &.header-scrolled {
      .header-scrolled_logo {
        @include dimensions(126px, 48px);
      }
    }
    .header-bottom-area {
      nav {
        margin: 0 -60px 0 0;

        a {
          @include fontSize(12px, 12px);
        }
      }
    }

    .lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder {
        text-transform: capitalize;
        font-weight: 400;
        color: $color-white;
        font-family: "Poppins-Regular", serif;
        font-size: 12px;
        margin-left: -10px;
    }
  }
  .topbar-contact-info {
    .contact-info-item {
      &:not(:last-child) {
        margin: 0 36px 0 0;
      }
    }
  }

  /* -- .radio-group -- */
  .radio-group {
    height: 40px;

    label {
      @include fontSize(13px, 13px);
    }
  }
}
@media (max-width: 767px) {
  .page-layout {
    margin: 60px 0 0;
  }
  .carousel {
    .our-works-item {
      margin: 0 auto;
    }
  }
  .tabset-area {
    .nav {
      &.nav-tabs {
        .nav-link {
          @include fontSize(12px, 13px);
        }
      }
    }
  }
  .page-layout {
    .page-layout-header-area {
      .page-layout-header_description {
        .page-layout-header_title {
          @include fontSize(30px, 30px);
          margin: 0;
        }
        .page-layout-header_desc {
          @include fontSize(13px, 20px);
        }
      }
    }
  }
  .request-quotation-area {
    .grid.gtc-2 {
      grid-template-columns: 1fr;
    }
    .contact-form-area {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
  .page-layout {
    .page-layout-header-area {
      height: 300px;

      .page-layout-img-blc {
        height: 300px;
      }
    }
  }
  .similar-products-area {
    margin: 0 0 50px;
  }
  .request-quotation-area {
    padding: 60px 0 0;

    .aside-contact-area {
      .informations-list-area {
        .information-list-item {
          margin: 0 0 40px;
          padding: 0 0 0 48px;

          .info-list-title {
            @include fontSize(16px, 16px);
            margin: 0 0 16px;
          }
          .info-list-desc {
            @include fontSize(14px, 20px);
          }

          &.our-location-icn {
            background-size: 35px auto;
          }
          &.quick-touch-icn {
            background-size: 32px auto;
          }
          &.call-us-now-icn {
            background-size: 28px auto;
          }
        }
      }
    }
  }
  .page-layout {
    .page-layout-header-area {
      .page-layout-header_description {
        padding: 0 80px;
      }
    }
  }
  .mobile-nav {
    display: block;
  }
  .topbar-contact-info {
    display: none;
  }
  header {
    .header-top-area {
      min-height: 60px;
      .container {
        position: relative;
      }
    }
    .logo {
      @include dimensions(110px, 40px);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
    }
    .header-top-area {
      box-shadow: 1px 0 3px 0 rgb(0 0 0 / 50%);
    }
    .header-bottom-area {
      background-color: $color-black;
      padding: 16px 40px 16px 16px;
      display: none;

      nav {
        display: block;
        a {
          display: block;
          &:not(:last-child) {
            margin: 0 0 15px 0;
          }
        }
      }
      .select-lang {
        right: -32px;
      }
      &.mobile-active {
        display: block;
      }
    }
    .lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder {
      text-transform: capitalize;
      font-weight: 400;
      color: $color-white;
      font-family: "Poppins-Regular", serif;
      font-size: 12px;
      margin-left: -10px;
      margin-bottom: 10px;
      margin-left: -10px;
  }
  }
}
@media (max-width: 460px) {
  .page-layout {
    .page-layout-header-area {
      height: 240px;

      .page-layout-img-blc {
        height: 240px;
      }
      .page-layout-header_description {
        .page-layout-header_title {
          @include fontSize(22px, 24px);
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .page-layout {
    .page-layout-header-area {
      height: 220px;

      .page-layout-img-blc {
        height: 220px;
      }
      .page-layout-header_description {
        padding: 0 50px;
      }
    }
  }
}
