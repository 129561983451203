@font-face {
    font-family: 'Dosis-ExtraBold';
    src: url('Dosis-ExtraBold.eot');
    src: url('Dosis-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Dosis-ExtraBold.woff2') format('woff2'),
        url('Dosis-ExtraBold.woff') format('woff'),
        url('Dosis-ExtraBold.ttf') format('truetype'),
        url('Dosis-ExtraBold.svg#Dosis-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-Light';
    src: url('Dosis-Light.eot');
    src: url('Dosis-Light.eot?#iefix') format('embedded-opentype'),
        url('Dosis-Light.woff2') format('woff2'),
        url('Dosis-Light.woff') format('woff'),
        url('Dosis-Light.ttf') format('truetype'),
        url('Dosis-Light.svg#Dosis-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-Medium';
    src: url('Dosis-Medium.eot');
    src: url('Dosis-Medium.eot?#iefix') format('embedded-opentype'),
        url('Dosis-Medium.woff2') format('woff2'),
        url('Dosis-Medium.woff') format('woff'),
        url('Dosis-Medium.ttf') format('truetype'),
        url('Dosis-Medium.svg#Dosis-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-Regular';
    src: url('Dosis-Regular.eot');
    src: url('Dosis-Regular.eot?#iefix') format('embedded-opentype'),
        url('Dosis-Regular.woff2') format('woff2'),
        url('Dosis-Regular.woff') format('woff'),
        url('Dosis-Regular.ttf') format('truetype'),
        url('Dosis-Regular.svg#Dosis-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-Bold';
    src: url('Dosis-Bold.eot');
    src: url('Dosis-Bold.eot?#iefix') format('embedded-opentype'),
        url('Dosis-Bold.woff2') format('woff2'),
        url('Dosis-Bold.woff') format('woff'),
        url('Dosis-Bold.ttf') format('truetype'),
        url('Dosis-Bold.svg#Dosis-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-ExtraLight';
    src: url('Dosis-ExtraLight.eot');
    src: url('Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Dosis-ExtraLight.woff2') format('woff2'),
        url('Dosis-ExtraLight.woff') format('woff'),
        url('Dosis-ExtraLight.ttf') format('truetype'),
        url('Dosis-ExtraLight.svg#Dosis-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis-SemiBold';
    src: url('Dosis-SemiBold.eot');
    src: url('Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Dosis-SemiBold.woff2') format('woff2'),
        url('Dosis-SemiBold.woff') format('woff'),
        url('Dosis-SemiBold.ttf') format('truetype'),
        url('Dosis-SemiBold.svg#Dosis-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

