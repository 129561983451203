/* # Variables ::
------------------------------------*/
/* -- Colors - */
/* -- Imgs url - */
/* # Mixin ::
------------------------------------*/
/* -- Truncate -- */
/* -- Flex -- */
/* -- Dimensions -- */
/* -- Fonts -- */
/* -- Background Image -- */
/* # Global ::
------------------------------------*/
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  pointer-events: none;
  z-index: 999;
}
.loader .img-loading {
  background-image: url("./../../assets/img/stpo_logo.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;
  animation: fade 1s infinite;
}

.loading-active {
  overflow: hidden;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body {
  padding: 0;
  margin: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* -- Grids -- */
.grid {
  display: grid;
}
.grid.gtc-1 {
  grid-template-columns: repeat(2, 1fr);
}
.grid.gtc-2 {
  grid-template-columns: 365px 1fr;
}
.grid.gtc-3 {
  grid-template-columns: 270px 1fr 1fr 1fr;
}
.grid.gtc-4 {
  grid-template-columns: 1fr 350px;
}
.grid.gtc-5 {
  grid-template-columns: 350px 1fr;
}
.grid.gtc-6 {
  grid-template-columns: repeat(3, 1fr);
}
.grid.gtc-7 {
  grid-template-columns: 270px 1fr 1fr;
}
.grid.gcg-1 {
  grid-column-gap: 10px;
}
.grid.gcg-2 {
  grid-column-gap: 20px;
}
.grid.gcg-3 {
  grid-column-gap: 24px;
}
.grid.gg-1 {
  grid-gap: 60px;
}
.grid.gg-2 {
  grid-gap: 50px;
}
.grid.gg-3 {
  grid-gap: 30px;
}

/* -- Titles -- */
.section-title-blc {
  max-width: 578px;
  margin: 0 auto 50px;
}
.section-title-blc .section-title {
  font-family: "Poppins-SemiBold", serif;
  font-size: 28px;
  line-height: 42px;
  color: #264E62;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

/* -- Page Layout Header -- */
.page-layout {
  margin: 95px 0 0;
}
.page-layout .page-layout-header-area {
  height: 410px;
  position: relative;
}
.page-layout .page-layout-header-area .page-layout-img-blc {
  width: 100%;
  height: 410px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.page-layout .page-layout-header-area .page-layout-img-blc:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.page-layout .page-layout-header-area .page-layout-img-blc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.page-layout .page-layout-header-area .page-layout-header_description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 160px;
}
.page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_title {
  font-size: 49px;
  line-height: 49px;
  color: #fff;
  font-family: "Poppins-Medium", serif;
  text-transform: uppercase;
  margin: 0 0 49px;
  text-align: center;
}
.page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_desc {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-family: "Poppins-Medium", serif;
  margin: 0;
  text-align: center;
}

/* -- Breadcrumb -- */
.breadcrumb-area .breadcrumb .breadcrumb-item {
  font-size: 14px;
  line-height: 14px;
  color: #212121;
  font-family: "Poppins-Light", serif;
}
.breadcrumb-area .breadcrumb .breadcrumb-item:not(:first-child):before {
  content: var(--bs-breadcrumb-divider, "|");
}
.breadcrumb-area .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  line-height: 14px;
  color: #212121;
  font-family: "Poppins-Light", serif;
}
.breadcrumb-area .breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}

/* -- our-works-slider-area -- */
.carousel-area .slide {
  margin: 0 15px;
}
.carousel-area .slick-list {
  padding: 15px 50px !important;
  max-width: 999px;
  margin: 0 auto;
}
.carousel-area .slick-arrow {
  width: 12px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 12px auto;
  background-position: center;
  z-index: 2;
}
.carousel-area .slick-arrow:before {
  display: none;
}
.carousel-area .slick-arrow.slick-prev {
  background-image: url("../../assets/img/svg/arrow-slider-icn.svg");
  left: 0;
  transform: rotate(180deg);
}
.carousel-area .slick-arrow.slick-next {
  background-image: url("../../assets/img/svg/arrow-slider-icn.svg");
  right: 0;
}

/* -- carousel -- */
.carousel .slick-list {
  padding: 15px 60px !important;
}
.carousel .our-works-item {
  max-width: 294px;
  min-height: 347px;
  box-shadow: 0 0 18px 0 rgba(91, 90, 90, 0.21);
  box-shadow: 10px 10px 9px 0px rgba(125, 125, 125, 0.1803921569);
  background-color: #fff;
  border-radius: 10px;
}
.carousel .our-works-item .our-works-item_img-blc {
  height: 178px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.carousel .our-works-item .our-works-item_img-blc img {
  width: 100%;
}
.carousel .our-works-item .our-works-item_description-area {
  padding: 20px;
  border-bottom: 5px solid #ef8e38;
  height: 188px;
  border-radius: 0 0 10px 10px;
}
.carousel .our-works-item .our-works-item_description-area .our-works-item_desc-title {
  font-family: "Poppins-Medium", serif;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin: 0 0 15px;
  text-align: center;
}
.carousel .our-works-item .our-works-item_description-area p {
  font-family: "Poppins-Light", serif;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  margin: 0 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  height: 37px;
  text-align: center;
}
.carousel .our-works-item .our-works-item_description-area .btns-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .our-works-item .our-works-item_description-area .btns-group .btn {
  min-width: 116px;
  height: 30px;
  font-size: 12px;
  line-height: 12px;
}

/* -- request-quotation-area -- */
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item {
  margin: 0 0 50px;
  padding: 0 0 0 60px;
  background-repeat: no-repeat;
  background-position: left 0;
  background-size: auto;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item:not(:last-child) {
  margin: 0 0 50px;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item.our-location-icn {
  background-image: url("../../assets/images/our-location-black-icn.png");
  background-size: 45px auto;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item.quick-touch-icn {
  background-image: url("../../assets/images/quick-touch-black-icn.png");
  background-size: 39px auto;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item.call-us-now-icn {
  background-image: url("../../assets/images/call-us-now-black-icn.png");
  background-size: 34px auto;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item.opening-hours-icn {
  background-image: url("../../assets/images/opening-hours-icn.png");
  background-size: 38px auto;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-title {
  font-size: 20px;
  line-height: 20px;
  color: #000;
  font-family: "Poppins-SemiBold", serif;
  margin: 0 0 20px;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-desc {
  font-size: 16px;
  line-height: 16px;
  color: #000;
  font-family: "Poppins-Regular", serif;
  margin: 0;
}
.request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-desc:not(:last-child) {
  margin: 0 0 15px;
}
.request-quotation-area .contact-form-area .contact-form_header {
  margin: 0 0 25px;
}
.request-quotation-area .contact-form-area .contact-form_header .contact-form_header-title {
  font-family: "Poppins-Bold", serif;
  font-size: 24px;
  line-height: 24px;
  color: #000;
  margin: 0 0 25px;
}
.request-quotation-area .contact-form-area .contact-form_header .contact-form_header-desc {
  font-family: "Poppins-Medium", serif;
  font-size: 16px;
  line-height: 25px;
  color: #000;
  margin: 0;
}
.request-quotation-area .contact-form-area .grid {
  margin: 0 0 25px;
}
.request-quotation-area .contact-form-area .btns-group .btn {
  width: 100%;
  height: 48px;
}
.request-quotation-area .contact-form-area .form-wrapper .form-style-1 {
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
  line-height: 14px;
  color: #989898;
  border-radius: 10px;
  border-color: #eeeeee;
  background-color: #eeeeee;
  height: 47px;
}
.request-quotation-area .contact-form-area .form-wrapper .textarea-blc .textarea-style {
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
  line-height: 14px;
  color: #989898;
  border-radius: 10px;
  border-color: #eeeeee;
  background-color: #eeeeee;
}
.request-quotation-area .contact-form-area .btns-group .btn {
  width: initial;
  min-width: 270px;
  margin: 0 0 0 auto;
}

/* -- tabset-area -- */
.tabset-area .nav.nav-tabs {
  box-shadow: 0 0 18px 0 rgba(91, 90, 90, 0.21);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.tabset-area .nav.nav-tabs .nav-item {
  position: relative;
}
.tabset-area .nav.nav-tabs .nav-item:not(:last-child):after {
  content: "";
  display: block;
  width: 1px;
  height: 48px;
  background-color: #dbdbdb;
  position: absolute;
  top: 5px;
  right: 0;
}
.tabset-area .nav.nav-tabs .nav-link {
  font-family: "Poppins-SemiBold", serif;
  font-size: 16px;
  line-height: 16px;
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
}
.tabset-area .nav.nav-tabs .nav-link.active {
  color: #ef8e38;
  border: 0;
  position: relative;
}
.tabset-area .nav.nav-tabs .nav-link.active:after {
  content: "";
  display: block;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -19px;
  left: 0;
  right: 0;
  background-color: #fff;
}
.tabset-area .tab-content {
  padding: 20px 0 0;
}
.tabset-area.style-2 .nav.nav-tabs {
  box-shadow: none;
  display: flex;
}
.tabset-area.style-2 .nav.nav-tabs .nav-item:not(:last-child):after {
  display: none;
}
.tabset-area.style-2 .nav.nav-tabs .nav-link.active {
  border-bottom: 2px solid #ef8e38;
}
.tabset-area.style-2 .nav.nav-tabs .nav-link.active:after {
  display: none;
}

/* -- similar-products-area -- */
.similar-products-area {
  margin: 0 0 100px;
}
.similar-products-area .similar-products_title-blc {
  margin: 0 0 40px;
}
.similar-products-area .similar-products_title-blc .similar-products_title {
  font-size: 18px;
  line-height: 18px;
  color: #ff6600;
  font-family: "Poppins-Medium", serif;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

/* -- request-quotation-area -- */
.request-quotation-area {
  padding: 80px 0 0;
  border-top: 2px solid #808080;
}

/* -- radio-group -- */
.radio-group {
  display: flex;
  height: 50px;
  background-color: #fff;
  border: 2px solid #f37121;
  border-radius: 5px;
}
.radio-group input[type=radio] {
  visibility: hidden;
  width: 0;
  height: 0;
}
.radio-group label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px 10px;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  color: #f37121;
  font-family: "Poppins-Light", serif;
}
.radio-group label:last-of-type {
  margin-right: 0;
}
.radio-group input[type=radio]:checked + label {
  background-color: #f37121;
  color: #fff;
}

/* -- Card -- */
.card {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1098039216);
  background-color: #f6f6f6;
  border-radius: 15px;
  border: 0;
  margin: 0 0 20px;
}
.card .card_header {
  border-bottom: 1px solid #bfbfbf;
  height: 40px;
  padding: 10px 18px;
  display: flex;
  align-items: center;
}
.card .card_header .card_header-title {
  margin: 0;
  font-size: 13px;
  line-height: 13px;
}
.card .card_body {
  padding: 10px;
}
.card.style-2 .card_header .card_header-title {
  background-repeat: no-repeat;
  background-position: left center;
  padding: 5px 0 5px 25px;
  color: #1F94A4;
}
.card.style-2 .card_header .card_header-title.client-icn {
  background-size: 20px auto;
}
.card.style-2 .card_header .card_header-title.fournisseur-icn {
  background-size: 22px auto;
}
.card.style-2 .card_header .card_header-title.product-icn {
  background-size: 18px auto;
}
.card.style-2 .card_header .card_header-title.state-icn {
  background-size: 18px auto;
}
.card.style-2 .card_header .card_header-title.paiement-icn {
  background-size: 18px auto;
}
.card.style-3 {
  padding: 25px 15px;
}
.card.style-5 {
  padding: 20px;
  box-shadow: none;
  border: 1px dashed #efe9e2;
  background-color: #fff;
  border-radius: 10px;
}
.card.style-5 .card_header {
  border-bottom: 0;
  height: initial;
  padding: 0;
  display: block;
  margin: 0 0 25px;
}
.card.style-5 .card_header .card_header-title {
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-family: "Poppins-SemiBold", serif;
}
.card.style-5 .card_body {
  padding: 10px;
}

/* -- ng-select-area -- */
.ng-select-area {
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.ng-select-area .ng-select .ng-select-container {
  min-width: 140px;
}
.ng-select-area .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 13px;
  line-height: 16px;
}
.ng-select-area .ng-select .ng-select-container .ng-value-container .ng-value {
  display: flex;
  align-items: center;
}
.ng-select-area .ng-select .ng-select-container .ng-value-container .ng-value img {
  margin: 0 7px 0 0;
  width: 18px;
  object-fit: fill;
}
.ng-select-area .ng-select .ng-select-container .ng-value-container .ng-value b {
  font-size: 11px;
  line-height: 11px;
}
.ng-select-area .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex;
  align-items: center;
}
.ng-select-area .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option img {
  margin: 0 7px 0 0;
  width: 18px;
  object-fit: fill;
}
.ng-select-area .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option b {
  font-size: 11px;
  line-height: 11px;
}

/* -- image-bloc -- */
.image-bloc {
  position: relative;
  width: 160px;
  height: 160px;
  box-shadow: 0 0 1em #ede9e5;
  margin: 14px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-bloc .edit-img-btn {
  position: absolute;
  right: 0;
  top: -8px;
  border: none;
  background-image: url("./../../assets/img/edit-file.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px auto;
  padding: 15px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0.1em 0 0.4em #ede9e5;
}
.image-bloc .file-upload-button input[type=file] {
  font-size: 100px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
}

/* -- file-input -- */
.file-input {
  cursor: pointer;
  background-color: rgba(239, 142, 56, 0.4);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='%23ef8e38' d='M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 35px auto;
  background-position: 20px center;
  padding: 25px 25px 25px 80px;
  border: 1px dashed #ef8e38;
  border-radius: 15px;
  margin: 0 0 15px;
}
.file-input .file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-input .file-input__label span {
  display: block;
}
.file-input .file-input__label span:nth-child(1) {
  font-size: 13px;
  line-height: 13px;
  color: #000;
  margin: 0 0 10px;
}
.file-input .file-input__label span:nth-child(2) {
  font-size: 12px;
  line-height: 12px;
  color: #bbb1a3;
}

/* -- Error Msg */
.error {
  font-size: 10px;
  line-height: 10px;
  color: red;
}

/* -- Radio Lang */
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.radio-tile-group .input-container {
  position: relative;
  margin: 0.5rem;
  width: 80px;
}
.radio-tile-group .input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
}
.radio-tile-group .input-container .radio-button:disabled {
  cursor: default;
}
.radio-tile-group .input-container .radio-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px solid #64748b;
  border-radius: 5px;
  padding: 10px;
  transition: transform 300ms ease;
}
.radio-tile-group .input-container .radio-tile .icon {
  background-repeat: no-repeat;
  background-size: 35px auto;
  background-position: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 auto 5px;
}
.radio-tile-group .input-container .radio-tile .icon.french-icon {
  background-image: url("./../../assets/img/france-flag.png");
}
.radio-tile-group .input-container .radio-tile .icon.anglais-icon {
  background-image: url("./../../assets/img/united-kingdom-flag.png");
}
.radio-tile-group .input-container .radio-tile .icon.ar-icon {
  background-image: url("./../../assets/img/tunisia-flag.png");
}
.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  color: #64748b;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile {
  background-color: #ed7136;
  border: 2px solid #ed7136;
  color: #fff;
  transform: scale(1.1, 1.1);
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
  color: #fff;
  background-color: #ed7136;
}

/* -- To Top -- */
.to-top {
  width: 36px;
  height: 36px;
  border-radius: 3px;
  border: 0;
  margin: 0 8px 12px 0;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: #f37121;
  background-image: url("./../../assets/img/arrow-up.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px auto;
}

/* -- radio-tile-group -- */
.editor-area .ql-formats button {
  border: 0;
  width: 35px;
  height: 30px;
}

.modal-new-private-product-area .carousel .slick-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-product-category-area .carousel .slick-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.lang-select.ng-select.ng-select-single {
  margin-right: 10px;
}
.lang-select.ng-select.ng-select-single:not(.select-lang-header) .ng-select-container {
  background-color: #fff;
  border: 1px solid #E2E1E1;
  border-radius: 6px;
  height: 34px;
}
.lang-select.ng-select.ng-select-single.select-lang-header {
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder {
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
  margin-left: -10px;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder + .ng-value {
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-select-container {
  background-color: transparent;
  border: none;
  height: auto;
  min-height: auto;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-select-container:hover {
  box-shadow: none;
}
.lang-select.ng-select.ng-select-single.select-lang-header span {
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins-Regular", serif;
  font-size: 12px;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel {
  width: max-content;
  border: none;
  top: 150%;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel span {
  color: #5a596a;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option-selected {
  background-color: #ef8e38;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option-selected span {
  color: #fff;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option:hover {
  background-color: #fff;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option:hover span {
  color: #ef8e38;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option:focus {
  background-color: #fff;
}
.lang-select.ng-select.ng-select-single.select-lang-header .ng-dropdown-panel .ng-option:focus span {
  color: #5a596a;
}
.lang-select.ng-select.ng-select-single.select-lang-header.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #fff;
}
.lang-select.ng-select.ng-select-single.select-lang-header:not(.ng-select-opened) > .ng-select-container .ng-arrow {
  border-color: #fff transparent transparent;
}
.lang-select.ng-select.ng-select-single .ng-select-container {
  width: inherit;
  font-weight: 400;
  background-clip: padding-box;
  font-family: "Poppins-Medium", serif;
  font-size: 12px;
  line-height: 15px;
  color: #000;
}
.lang-select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input {
  visibility: hidden;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}
.p-paginator .p-paginator-current {
  height: 2rem;
}
.p-paginator .p-paginator-first {
  height: 2rem;
}
.p-paginator .p-paginator-prev {
  height: 2rem;
}
.p-paginator .p-paginator-next {
  height: 2rem;
}
.p-paginator .p-paginator-last {
  height: 2rem;
}
.p-paginator .p-dropdown {
  height: fit-content;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding: 2px 5px;
}

.p-datatable .p-paginator-bottom {
  padding: 5px 0px;
}

.p-dialog-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.p-dialog-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}
.p-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #acabab;
}

.cke_top {
  padding-top: 20px !important;
  background: #fff !important;
}

.cke_chrome {
  border-radius: 0.25rem !important;
  padding: 1px !important;
}

.cke_bottom {
  background: #fff !important;
}

.form-label-placeholder {
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
}
.form-label-placeholder input, .form-label-placeholder textarea, .form-label-placeholder .form-html {
  font-family: "Poppins-Medium", serif;
  z-index: 1;
  background-color: transparent;
  font-size: 12px;
}
.form-label-placeholder input::placeholder, .form-label-placeholder textarea::placeholder, .form-label-placeholder .form-html::placeholder {
  opacity: 0;
}
.form-label-placeholder input:focus, .form-label-placeholder input.focus, .form-label-placeholder textarea:focus, .form-label-placeholder textarea.focus, .form-label-placeholder .form-html:focus, .form-label-placeholder .form-html.focus {
  box-shadow: none;
}
.form-label-placeholder input:focus + .form-label, .form-label-placeholder input:focus + .label-style, .form-label-placeholder input.focus + .form-label, .form-label-placeholder input.focus + .label-style, .form-label-placeholder textarea:focus + .form-label, .form-label-placeholder textarea:focus + .label-style, .form-label-placeholder textarea.focus + .form-label, .form-label-placeholder textarea.focus + .label-style, .form-label-placeholder .form-html:focus + .form-label, .form-label-placeholder .form-html:focus + .label-style, .form-label-placeholder .form-html.focus + .form-label, .form-label-placeholder .form-html.focus + .label-style {
  top: -10px;
  z-index: 9;
}
.form-label-placeholder input + .form-label, .form-label-placeholder input + .label-style, .form-label-placeholder textarea + .form-label, .form-label-placeholder textarea + .label-style, .form-label-placeholder .form-html + .form-label, .form-label-placeholder .form-html + .label-style {
  text-transform: capitalize;
  right: auto;
  padding: 0 5px;
  background-color: #fff;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 0.2s;
  position: absolute;
  top: 10px;
  left: 8px;
  transition: all 0.2s;
  color: #91a9c9 !important;
  letter-spacing: 0.1px;
  font-size: 10px !important;
  line-height: 10px !important;
  padding: 5px;
  background-color: #fff;
  z-index: -1;
  text-transform: uppercase;
  margin-left: 0 !important;
}
.form-label-placeholder ckeditor + .form-label {
  text-transform: capitalize;
  right: auto;
  padding: 0 5px;
  background-color: #fff;
  position: absolute;
  top: -11px;
  left: 8px;
  transition: all 0.2s;
  letter-spacing: 0.1px;
  font-size: 10px;
  line-height: 10px;
  color: #91a9c9;
  padding: 5px;
  background-color: #fff;
  z-index: 9;
  text-transform: uppercase;
}
.form-label-placeholder input {
  padding: 10px 10px;
}
.form-label-placeholder textarea {
  padding: 13px 10px;
  height: 106px;
}

.form-label-placeholder-dropdown {
  position: relative;
}
.form-label-placeholder-dropdown p-dropdown + .form-label {
  text-transform: capitalize;
  right: auto;
  padding: 0 5px;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 8px;
  transition: all 0.2s;
  letter-spacing: 0.1px;
  font-size: 10px;
  line-height: 10px;
  color: #91a9c9;
  padding: 5px;
  background-color: #fff;
  z-index: 0;
  text-transform: uppercase;
}
.form-label-placeholder-dropdown p-dropdown .p-placeholder {
  color: rgba(0, 0, 0, 0) !important;
  z-index: 9;
}
.form-label-placeholder-dropdown p-dropdown.p-inputwrapper-focus + .form-label, .form-label-placeholder-dropdown p-dropdown.focus + .form-label {
  top: -11px;
  z-index: 9;
}
.form-label-placeholder-dropdown p-dropdown.p-inputwrapper-focus .p-dropdown, .form-label-placeholder-dropdown p-dropdown.focus .p-dropdown {
  box-shadow: none !important;
  border-color: #86b7fe;
}
.form-label-placeholder-dropdown p-dropdown .p-dropdown:hover {
  box-shadow: none !important;
  border-color: #86b7fe;
}

.form-label-file .form-label {
  text-transform: capitalize;
  right: auto;
  padding: 0 5px;
  background-color: #fff;
  transition: all 0.2s;
  letter-spacing: 0.1px;
  font-size: 10px;
  line-height: 10px;
  color: #91a9c9;
  padding: 5px;
  background-color: #fff;
  z-index: 0;
  text-transform: uppercase;
}
.form-label-file .form-label.orange {
  color: #ef8e38;
}

/* # $Mobile ::
------------------------------------*/
@media (max-width: 1440px) {
  .page-layout {
    margin: 76px 0 0;
  }
  .tabset-area .nav.nav-tabs .nav-link {
    font-size: 13px;
    line-height: 13px;
    height: 40px;
  }
  /*
  .carousel-area {
    .slick-arrow {
      @include dimensions(24px, 36px);
      background-size: 24px auto;
    }
  }
  */
  .breadcrumb-area .breadcrumb .breadcrumb-item {
    font-size: 12px;
    line-height: 15px;
  }
  .breadcrumb-area .breadcrumb .breadcrumb-item a {
    font-size: 12px;
    line-height: 12px;
  }
  .page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_title {
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 40px;
  }
  .page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_desc {
    font-size: 18px;
    line-height: 26px;
  }
  .grid.gg-2 {
    grid-gap: 30px;
  }
  .request-quotation-area {
    padding: 50px 0 0;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item {
    margin: 0 0 40px;
    padding: 0 0 0 50px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item:not(:last-child) {
    margin: 0 0 40px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.our-location-icn {
    background-size: 35px auto;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.quick-touch-icn {
    background-size: 30px auto;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.call-us-now-icn {
    background-size: 30px auto;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-title {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 10px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-desc {
    font-size: 13px;
  }
}
@media (max-width: 992px) {
  .request-quotation-area .contact-form-area .grid {
    margin: 0 0 10px;
    grid-template-columns: repeat(1, 1fr);
  }
  .request-quotation-area .contact-form-area .grid.gg-3 {
    grid-gap: 10px;
  }
  .request-quotation-area .contact-form-area .contact-form_header {
    margin: 0 0 20px;
  }
  .request-quotation-area .contact-form-area .contact-form_header .contact-form_header-title {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 20px;
  }
  .request-quotation-area .contact-form-area .contact-form_header .contact-form_header-desc {
    font-size: 13px;
    line-height: 20px;
  }
  .request-quotation-area .contact-form-area .btns-group .btn {
    min-width: 100%;
    margin: 0;
  }
  .request-quotation-area .contact-form-area .form-wrapper .form-style-1 {
    font-size: 12px;
    line-height: 12px;
    height: 35px;
  }
  header.header-scrolled .header-scrolled_logo {
    width: 126px;
    height: 48px;
  }
  header .header-bottom-area nav {
    margin: 0 -60px 0 0;
  }
  header .header-bottom-area nav a {
    font-size: 12px;
    line-height: 12px;
  }
  header .lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder {
    text-transform: capitalize;
    font-weight: 400;
    color: #fff;
    font-family: "Poppins-Regular", serif;
    font-size: 12px;
    margin-left: -10px;
  }
  .topbar-contact-info .contact-info-item:not(:last-child) {
    margin: 0 36px 0 0;
  }
  /* -- .radio-group -- */
  .radio-group {
    height: 40px;
  }
  .radio-group label {
    font-size: 13px;
    line-height: 13px;
  }
}
@media (max-width: 767px) {
  .page-layout {
    margin: 60px 0 0;
  }
  .carousel .our-works-item {
    margin: 0 auto;
  }
  .tabset-area .nav.nav-tabs .nav-link {
    font-size: 12px;
    line-height: 13px;
  }
  .page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_title {
    font-size: 30px;
    line-height: 30px;
    margin: 0;
  }
  .page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_desc {
    font-size: 13px;
    line-height: 20px;
  }
  .request-quotation-area .grid.gtc-2 {
    grid-template-columns: 1fr;
  }
  .request-quotation-area .contact-form-area .grid {
    grid-template-columns: 1fr;
  }
  .page-layout .page-layout-header-area {
    height: 300px;
  }
  .page-layout .page-layout-header-area .page-layout-img-blc {
    height: 300px;
  }
  .similar-products-area {
    margin: 0 0 50px;
  }
  .request-quotation-area {
    padding: 60px 0 0;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item {
    margin: 0 0 40px;
    padding: 0 0 0 48px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-title {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 16px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item .info-list-desc {
    font-size: 14px;
    line-height: 20px;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.our-location-icn {
    background-size: 35px auto;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.quick-touch-icn {
    background-size: 32px auto;
  }
  .request-quotation-area .aside-contact-area .informations-list-area .information-list-item.call-us-now-icn {
    background-size: 28px auto;
  }
  .page-layout .page-layout-header-area .page-layout-header_description {
    padding: 0 80px;
  }
  .mobile-nav {
    display: block;
  }
  .topbar-contact-info {
    display: none;
  }
  header .header-top-area {
    min-height: 60px;
  }
  header .header-top-area .container {
    position: relative;
  }
  header .logo {
    width: 110px;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
  }
  header .header-top-area {
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.5);
  }
  header .header-bottom-area {
    background-color: #000;
    padding: 16px 40px 16px 16px;
    display: none;
  }
  header .header-bottom-area nav {
    display: block;
  }
  header .header-bottom-area nav a {
    display: block;
  }
  header .header-bottom-area nav a:not(:last-child) {
    margin: 0 0 15px 0;
  }
  header .header-bottom-area .select-lang {
    right: -32px;
  }
  header .header-bottom-area.mobile-active {
    display: block;
  }
  header .lang-select.ng-select.ng-select-single.select-lang-header .ng-placeholder {
    text-transform: capitalize;
    font-weight: 400;
    color: #fff;
    font-family: "Poppins-Regular", serif;
    font-size: 12px;
    margin-left: -10px;
    margin-bottom: 10px;
    margin-left: -10px;
  }
}
@media (max-width: 460px) {
  .page-layout .page-layout-header-area {
    height: 240px;
  }
  .page-layout .page-layout-header-area .page-layout-img-blc {
    height: 240px;
  }
  .page-layout .page-layout-header-area .page-layout-header_description .page-layout-header_title {
    font-size: 22px;
    line-height: 24px;
  }
}
@media (max-width: 360px) {
  .page-layout .page-layout-header-area {
    height: 220px;
  }
  .page-layout .page-layout-header-area .page-layout-img-blc {
    height: 220px;
  }
  .page-layout .page-layout-header-area .page-layout-header_description {
    padding: 0 50px;
  }
}
/* # Buttons ::
------------------------------------*/
.btn {
  width: 100%;
}
.btn.btn-border {
  min-width: 84px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  font-family: "Poppins-Light", serif;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  display: block;
  transition: all 0.3s;
  height: 35px;
  width: initial;
}
.btn.btn-border:hover {
  background-color: rgba(239, 142, 56, 0.5098039216);
  border: 2px solid #ef8e38;
}
.btn.btn-gray {
  border-radius: 10px;
  background-color: #f8f6f2;
  border: 1px solid #8e96b8;
  font-family: "Poppins-Light", serif;
  font-size: 14px;
  line-height: 14px;
  color: #8e96b8;
  display: block;
  transition: all 0.3s;
  height: 35px;
  width: initial;
}
.btn.btn-orange {
  border-radius: 10px;
  background-color: #ef8e38;
  border: 2px solid #ef8e38;
  font-family: "Inter-Medium", serif;
  font-size: 15px;
  line-height: 15px;
  color: #fff;
  display: block;
  transition: all 0.3s;
  height: 45px;
  width: initial;
}
.btn.btn-orange.style-1 {
  min-width: 185px;
  margin: 0 auto;
}
.btn.btn-orange.style-2 {
  min-width: 160px;
  height: 38px;
}
.btn.btn-orange:hover {
  background-color: rgba(239, 142, 56, 0.5098039216);
}
.btn.btn-outline-orange {
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #ef8e38;
  font-family: "Poppins-Light", serif;
  font-size: 14px;
  line-height: 14px;
  color: #ef8e38;
  display: block;
  transition: all 0.3s;
  height: 35px;
  width: initial;
}
.btn.btn-outline-orange.style-1 {
  min-width: 84px;
  margin: 0 auto;
}
.btn.btn-outline-orange.style-2 {
  min-width: 160px;
  height: 38px;
}
.btn.btn-outline-orange.style-3 {
  min-width: 160px;
  height: 40px;
  border-width: 1px;
}
.btn.btn-outline-orange:hover {
  background-color: #ef8e38;
  color: #fff;
}
.btn.btn-more {
  background-image: url("../../assets/images/description-toggle-icn.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 29px auto;
  width: 29px;
  height: 29px;
  display: block;
  margin: 0 auto;
}
.btn.btn-blue-viking {
  border-radius: 10px;
  font-family: "Poppins-Light", serif;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  display: block;
  transition: all 0.3s;
  height: 35px;
  width: initial;
  padding: 0.375rem 0.75rem 0.375rem 2.5rem;
  background-color: #4fc9da;
  background-image: url("./../../assets/img/icon/add.png");
  background-repeat: no-repeat;
  background-size: 13px auto;
  background-position: 12px center;
}

.title-bloc {
  display: flex;
}
.title-bloc .back {
  background-image: url("../../assets/img/svg/arrow-back.svg");
  background-position: left center;
  background-size: 24px auto;
  background-repeat: no-repeat;
  padding: 0 0 0 30px;
  cursor: pointer;
}

/* # $Mobile ::
------------------------------------*/
@media (max-width: 1440px) {
  .btn.btn-orange {
    font-size: 12px;
    line-height: 12px;
  }
}
@media (max-width: 992px) {
  .btn.btn-orange.style-1 {
    min-width: 145px;
    height: 40px;
  }
}
/* # Forms ::
------------------------------------*/
.form-wrapper .form-style-1 {
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  border-radius: 10px;
  border-color: rgb(132, 132, 132);
  height: 47px;
}
.form-wrapper .form-style-1::-webkit-input-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .form-style-1::-moz-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .form-style-1:-ms-input-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .form-style-1:-moz-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .form-style-1.error {
  border-color: red;
  color: red;
}
.form-wrapper .form-style-1.error::-webkit-input-placeholder {
  color: red;
}
.form-wrapper .form-style-1.error::-moz-placeholder {
  color: red;
}
.form-wrapper .form-style-1.error:-ms-input-placeholder {
  color: red;
}
.form-wrapper .form-style-1.error:-moz-placeholder {
  color: red;
}
.form-wrapper .textarea-blc {
  margin: 0 0 25px;
}
.form-wrapper .textarea-blc .textarea-style {
  font-family: "Poppins-Regular", serif;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  border-radius: 10px;
  border: 1px solid rgb(132, 132, 132);
  width: 100%;
  height: 120px;
  padding: 0.75rem;
  margin: 0;
}
.form-wrapper .textarea-blc .textarea-style::-webkit-input-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .textarea-blc .textarea-style::-moz-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .textarea-blc .textarea-style:-ms-input-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .textarea-blc .textarea-style:-moz-placeholder {
  color: #aaaaaa;
  font-style: italic;
}
.form-wrapper .textarea-blc .textarea-style.error {
  border-color: red;
  color: red;
}
.form-wrapper .textarea-blc .textarea-style.error::-webkit-input-placeholder {
  color: red;
}
.form-wrapper .textarea-blc .textarea-style.error::-moz-placeholder {
  color: red;
}
.form-wrapper .textarea-blc .textarea-style.error:-ms-input-placeholder {
  color: red;
}
.form-wrapper .textarea-blc .textarea-style.error:-moz-placeholder {
  color: red;
}

.error-msg {
  margin: 7px 0 0;
  font-size: 10px;
  line-height: 12px;
  color: red;
  font-family: "Poppins-Regular", serif;
}

/* -- form-control -- */
.form-control.style-2 {
  border: 1px solid #968e7e;
  margin: 0 0 15px;
  font-size: 12px;
  line-height: 12px;
  color: #968e7e;
  height: 35px;
}

/* # $Mobile ::
------------------------------------*/
@media (max-width: 992px) {
  .form-wrapper .textarea-blc {
    margin: 0 0 10px;
  }
}